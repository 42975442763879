export const platforms = [
  {
    name: "IOS",
    icon: "icon-apple"
  },
  {
    name: "Android",
    icon: "icon-android"
  },
  {
    name: "Windows",
    icon: "icon-windows"
  }
];

export const platformsRegExp = ":platform(android|ios|windows)";
