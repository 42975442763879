import React, { useCallback, useMemo, useState } from "react";

import { useLocationHook } from "../../hooks/useLocation";
import { ParamsContext } from "./paramsContext";

export const ParamsState = ({ children }) => {
  const filterParams = {
    status: {
      enabled: false,
      disabled: false,
      quarantined: false
    }
  };
  const tableParams = {
    page: 1,
    limit: 30
  };

  const [params, changeParams] = useState({
    ...tableParams,
    ...filterParams
  });
  const { currentUrl } = useLocationHook();

  const resetFilterParams = useCallback(() => {
    changeParams((params) => ({
      ...filterParams,
      ...tableParams,
      entity: params.entity
    }));
  }, []);

  const changeStatus = useCallback((key, checked) => {
    changeParams((params) => ({
      ...params,
      status: {
        ...params.status,
        [key]: checked
      }
    }));
  }, []);

  const changeEntity = useCallback(
    (entity) => {
      changeParams({
        ...tableParams,
        ...filterParams,
        entity
      });
    },
    [tableParams, filterParams]
  );

  const changeLimit = useCallback((limit) => {
    changeParams((params) => ({
      ...params,
      limit
    }));
  }, []);

  useMemo(() => {
    const currentPath = currentUrl.replace("/", "").split("/");
    const filteredPathArray = currentPath.filter((p) => !Number(p));
    const currentEntity = filteredPathArray[filteredPathArray.length - 1];
    if (currentPath.length && params.entity !== currentEntity) {
      changeEntity(currentEntity);
    }
  }, [currentUrl]);

  const value = useMemo(
    () => ({
      params,
      changeParams,
      resetFilterParams,
      changeEntity,
      changeStatus,
      changeLimit
    }),
    [params]
  );

  return (
    <ParamsContext.Provider value={value}>{children}</ParamsContext.Provider>
  );
};
