import React, { useMemo, useState } from "react";

import { parseInputName, removeProperties } from "../../utils/objectHelper";
import { FormContext } from "./formContext";

export const FormState = ({ children }) => {
  const [form, changeFormFields] = useState({});
  const [errors, setErrors] = useState({});

  const validateFields = (schema) =>
    schema.validate(form, { abortEarly: false }).catch((errors) => {
      const newErrorsObj = Object.assign(
        ...errors.inner.map(({ path, message }) => ({
          [path]: message
        }))
      );

      setErrors(newErrorsObj);
      return false;
    });

  const onChangeInput = ({ name, value }, trimValue) => {
    let updatedState = {};
    value = trimValue ? value.trim() : value;

    if (name.includes(".")) {
      const { parentName, thisName } = parseInputName(name);
      if (!form[parentName]) return;

      updatedState = {
        ...form,
        [parentName]: {
          ...form[parentName],
          [thisName]: value
        }
      };
    } else {
      updatedState = {
        ...form,
        [name]: value
      };
    }

    changeFormFields(updatedState);

    if (errors[name]) {
      setErrors(removeProperties(errors, [name]));
    }
  };

  const getValue = (name) => {
    if (name.includes(".")) {
      const { parentName, thisName } = parseInputName(name);
      return form && form[parentName] ? form[parentName][thisName] : "";
    }

    return form && form[name] !== undefined && form[name] !== null
      ? form[name]
      : "";
  };

  const value = useMemo(
    () => ({
      changeFormFields,
      form,
      validateFields,
      errors,
      setErrors,
      onChangeInput,
      getValue,
      clearFields: () => changeFormFields({})
    }),
    [form, errors]
  );

  return <FormContext.Provider value={value}>{children}</FormContext.Provider>;
};
