import React, { useContext, useMemo, useState } from "react";

import { InvitesContext } from "../invites/invitesContext";
import { ParamsContext } from "../params/paramsContext";
import { PlansContext } from "../plans/plansContext";
import { PlatformContext } from "../platform/platformContext";
import { PopupsContext } from "../popup/popupsContext";
import { TestsContext } from "../tests/testsContext";
import { UsersContext } from "../users/usersContext";
import { TableContext } from "./tableContext";

export const TableState = ({ children }) => {
  const [selectedRows, selectRow] = useState({});
  const { params, changeParams } = useContext(ParamsContext);
  const { toggleOpenPopup } = useContext(PopupsContext);
  const { deleteInvites, getInvites } = useContext(InvitesContext);
  const { deleteUser, getUsers } = useContext(UsersContext);
  const { removeTestsFromTestsPlan, getPlan, currentPlanId } = useContext(
    PlansContext
  );
  const { getTests } = useContext(TestsContext);
  const { currentPlatform } = useContext(PlatformContext);

  const clearSelectedRows = () => selectRow({});

  const selectAllRows = ({ target: { checked, name } }) => {
    const checkBoxes = {};

    for (let el of document.querySelectorAll(`input[type="checkbox"]`)) {
      const checkBoxName = el.getAttribute("name");

      if (checkBoxName) {
        checkBoxes[checkBoxName] = checked;
      }
    }

    selectRow({
      ...selectedRows,
      ...checkBoxes,
      [name]: checked
    });
  };

  const getSelectedItemsId = () => {
    return Object.keys(selectedRows).filter(
      (key) => selectedRows[key] && !key.includes("selectAll")
    );
  };

  const selectOneRow = ({ target: { checked, name } }) => {
    const isLast = getSelectedItemsId();
    !checked && isLast.length === 1 && isLast[0] === name
      ? clearSelectedRows()
      : selectRow({ ...selectedRows, [name]: checked });
  };

  const sort = (target, columnName) => {
    target = target.tagName === "I" ? target.parentElement : target;
    const order = target.classList.contains("desc") ? "asc" : "desc";
    const sortByArr = columnName.split(" ");
    const sortBy =
      sortByArr.length > 1
        ? `${sortByArr[0].toLowerCase()}${sortByArr[1]}`
        : sortByArr[0].toLowerCase();
    changeParams((prev) => ({
      ...prev,
      sort: sortBy,
      order
    }));
    target.classList.toggle("desc");
  };

  const remove = () => {
    const ids = getSelectedItemsId();

    switch (params.entity) {
      case "organizations":
        deleteUser(
          ids
            .map((id) => id.split("group")[1])
            .filter((item) => item !== undefined)
        );
        break;
      case "test-plan":
        removeTestsFromTestsPlan(
          ids
            .map((id) => id.split("test")[1])
            .filter((item) => item !== undefined)
        );
        break;
      case "users":
        deleteUser(ids);
        break;
      case "invites":
        deleteInvites(ids);
        break;
      default:
        return false;
    }

    clearSelectedRows();
    toggleOpenPopup({});
  };

  const deleteItemsString = () => `(${getSelectedItemsId().length})`;

  useMemo(() => {
    // get list and update table when user changed entity or platform
    switch (params.entity) {
      case "invites":
        getInvites();
        break;
      case "users":
      case "organizations":
        getUsers();
        break;
      case "tests":
        getTests();
        break;
      case "test-plan":
        getPlan(window.location.pathname.split("/")[3]);
        break;
      default:
        return;
    }
  }, [params, currentPlatform]);

  const value = useMemo(
    () => ({
      selectedRows,
      selectOneRow,
      selectAllRows,
      getSelectedCount: getSelectedItemsId().length,
      getSelectedItemsId: getSelectedItemsId(),
      clearSelectedRows,
      deleteItemsString,
      remove,
      sort
    }),
    [selectedRows, params]
  );

  return (
    <TableContext.Provider value={value}>{children}</TableContext.Provider>
  );
};
