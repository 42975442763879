import * as yup from "yup";

export const createUserSchema = yup.object().shape({
  firstName: yup
    .string()
    .min(3, "Must be more than or equal 3 characters")
    .max(50, "Must be less than or equal 50 characters")
    .required(),
  email: yup.string().email().required()
});

export const createOrganizationSchema = yup.object().shape({
  name: yup
    .string()
    .min(3, "Must be more than or equal 3 characters")
    .max(50, "Must be less than or equal 50 characters")
    .required(),
  email: yup.string().email().required()
});

export const loginSchema = yup.object().shape({
  email: yup.string().email().required().nullable(),
  password: yup
    .string()
    .max(20)
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
      "Your password is invalid"
    )
    .required()
    .nullable()
});

export const emailSchema = yup.object().shape({
  email: yup.string().email().required().nullable()
});

export const testInfoSchema = yup.object().shape({
  jiraLink: yup
    .string()
    .matches(/browse/, {
      message: "Must be a jira link",
      excludeEmptyString: true
    })
    .url()
    .max(100, "Must be less than or equal 100 characters")
    .nullable(),
  testRailLink: yup
    .string()
    .matches(/testrail/, {
      message: "Must be a jira link",
      excludeEmptyString: true
    })
    .url()
    .max(100, "Must be less than or equal 100 characters")
    .nullable(),
  comment: yup.string().max(500).nullable()
});

export const resetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .max(20)
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
      "Password must have at least 8 characters with at least one Capital letter, at least one lower case letter, and at least one number."
    )
    .required(),
  passwordCheck: yup
    .string()
    .oneOf([yup.ref("password")], "Passwords must match")
    .required("Password check is a required field")
});

export const inviteSchema = yup.object().shape({
  firstName: yup.string().min(3).max(50).nullable().required(),
  lastName: yup.string().min(3).max(50),
  password: yup
    .string()
    .max(20)
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
      "Password must have at least 8 characters with at least one Capital letter, at least one lower case letter, and at least one number."
    )
    .required(),
  passwordCheck: yup
    .string()
    .oneOf([yup.ref("password")], "Passwords must match")
    .required("Password check is a required field")
});

export const nodeSchema = (type: number) =>
  yup.object().shape({
    host: yup
      .string()
      .min(6)
      .max(100)
      .matches(
        /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))|(^\s*((?=.{1,255}$)(?=.*[A-Za-z].*)[0-9A-Za-z](?:(?:[0-9A-Za-z]|\b-){0,61}[0-9A-Za-z])?(?:\.[0-9A-Za-z](?:(?:[0-9A-Za-z]|\b-){0,61}[0-9A-Za-z])?)*)\s*$)/,
        "Must be a valid host"
      )
      .required()
      .nullable(),
    name: yup.string().min(3).max(100).required().nullable(),
    port: yup.number().typeError("Required field").min(1).max(65535).required(),
    username: yup.string().min(1).max(100).required().nullable(),
    pathToCertificate: yup
      .string()
      .strict(false)
      .trim()
      .min(3, "Must be more than or equal 3 characters")
      .max(300, "Must be less than or equal 300 characters")
      .required("Required field")
      .nullable(),
    deploymentPath: yup
      .string()
      .strict(false)
      .trim()
      .min(3, "Must be more than or equal 3 characters")
      .max(300, "Must be less than or equal 300 characters")
      .required("Required field")
      .nullable(),
    xcodePath:
      type === 0
        ? yup
            .string()
            .strict(false)
            .trim()
            .min(3, "Must be more than or equal 3 characters")
            .max(200, "Must be less than or equal 200 characters")
            .required("Required field")
            .nullable()
        : yup.string().nullable().notRequired(),
    androidSdkPath:
      type === 1
        ? yup
            .string()
            .strict(false)
            .trim()
            .min(3, "Must be more than or equal 3 characters")
            .max(200, "Must be less than or equal 200 characters")
            .required("Required field")
            .nullable()
        : yup.string().nullable().notRequired(),
    UDID:
      type !== 4
        ? yup
            .object()
            .shape(
              {
                devices: yup.array().when("simulators", {
                  is: (simulators: Array<string>) =>
                    simulators && simulators.length,
                  then: yup
                    .array()
                    .of(
                      yup
                        .string()
                        .min(6, "Must be more than or equal 6 characters")
                        .max(100, "Must be less than or equal 100 characters")
                    ),
                  otherwise: yup
                    .array()
                    .of(
                      yup
                        .string()
                        .min(6, "Must be more than or equal 6 characters")
                        .max(100, "Must be less than or equal 100 characters")
                    )
                    .required("Simulators or Devices must have at least one id")
                }),
                simulators: yup.array().when("devices", {
                  is: (devices: Array<string>) => devices && devices.length,
                  then: yup
                    .array()
                    .of(
                      yup
                        .string()
                        .min(6, "Must be more than or equal 6 characters")
                        .max(100, "Must be less than or equal 100 characters")
                    ),
                  otherwise: yup
                    .array()
                    .of(
                      yup
                        .string()
                        .min(6, "Must be more than or equal 6 characters")
                        .max(100, "Must be less than or equal 100 characters")
                    )
                    .required("Simulators or Devices must have at least one id")
                })
              },
              [["devices", "simulators"]]
            )
            .required("Required field")
        : yup.object().nullable()
  });

export const settingsSchema = (type: number) =>
  yup.object().shape({
    setUpScriptPath: yup
      .string()
      .trim()
      .max(300, "Must be less than or equal to 300 characters")
      .nullable()
      .notRequired(),
    tearDownScriptPath: yup
      .string()
      .trim()
      .max(300, "Must be less than or equal 300 characters")
      .nullable()
      .notRequired(),
    reportTitle:
      type === 1
        ? yup
            .string()
            .min(3, "Must be more than or equal 3 characters")
            .max(100, "Must be less than or equal 100 characters")
            .required("Required field")
            .nullable()
        : yup.string().notRequired(),
    reportSubtitle:
      type === 1
        ? yup
            .string()
            .min(3, "Must be more than or equal 3 characters")
            .max(100, "Must be less than or equal 100 characters")
            .nullable()
        : yup.string().nullable().notRequired(),
    testPackage:
      type === 1
        ? yup
            .string()
            .trim()
            .min(3, "Must be more than or equal 3 characters")
            .max(300, "Must be less than or equal 300 characters")
            .required("Required field")
            .nullable()
        : yup.string().notRequired(),
    xctestrunPath:
      type === 0
        ? yup
            .string()
            .trim()
            .min(3, "Must be more than or equal 3 characters")
            .max(300, "Must be less than or equal 300 characters")
            .required("Required field")
            .nullable()
        : yup.string().notRequired(),
    appPackage:
      type === 1
        ? yup
            .string()
            .trim()
            .min(3, "Must be more than or equal 3 characters")
            .max(300, "Must be less than or equal 300 characters")
            .required("Required field")
            .nullable()
        : yup.string().notRequired(),
    outputDirectoryPath: yup
      .string()
      .trim()
      .min(3, "Must be more than or equal 3 characters")
      .max(300, "Must be less than or equal 300 characters")
      .required("Required field")
      .nullable(),
    testsBucket:
      type === 0
        ? yup
            .number()
            .typeError("Required field")
            .min(0, "Must be more than or equal to 0")
            .required("Required field")
        : yup.string().notRequired(),
    globalRetryLimit:
      type === 1
        ? yup
            .number()
            .typeError("Required field")
            .min(0, "Must be more than or equal to 0")
            .required("Required field")
        : yup.string().notRequired(),
    rerunFailedTest:
      type === 0
        ? yup
            .number()
            .typeError("Required field")
            .min(0, "Must be more than or equal to 0")
            .max(50, "Must be less than or equal to 50")
            .required("Required field")
        : yup.string().notRequired(),
    testRetryLimit:
      type === 1
        ? yup
            .number()
            .typeError("Required field")
            .min(1, "Must be more than or equal to 1")
            .max(50, "Must be less than or equal to 50")
            .required("Required field")
        : yup.string().notRequired(),
    testsExecutionTimeout: yup
      .number()
      .typeError("Required field")
      .min(20, "Must be more than or equal to 20")
      .max(300, "Must be less than or equal to 300")
      .required("Required field")
  });

export const planSchema = (plans: Array<string>) =>
  yup.object().shape({
    name: yup.string().min(3).max(100).notOneOf(plans).required()
  });

export const createEnvVariable = (keys: Array<string>) =>
  yup.object().shape({
    envKey: yup
      .string()
      .min(1, "Must be more than or equal 1 characters")
      .notOneOf(keys)
      .required(),
    envValue: yup
      .string()
      .min(1, "Must be more than or equal 1 characters")
      .required()
  });
