import { lazy } from "react";

import * as routes from "../constants/routes";
const Organizations = lazy(() => import("../pages/Organizations"));
const Nodes = lazy(() => import("../pages/Nodes"));
const Node = lazy(() => import("../pages/Node"));
const Settings = lazy(() => import("../pages/Settings"));
const Plan = lazy(() => import("../pages/Plan"));
const Tests = lazy(() => import("../pages/Tests"));
const Users = lazy(() => import("../pages/Users"));
const Invites = lazy(() => import("../pages/Invites"));
const TestPlans = lazy(() => import("../pages/TestPlans"));

/*
 * 1. Make title optional
 * 2. Make title multi type support ie: (string, node, react element)
 * */

export default [
  {
    component: Organizations,
    path: routes.ORGANIZATIONS,
    title: "Organizations",
    name: "Organizations",
    permissions: [0],
    iconClass: "icon-organization",
    display: true
  },
  {
    component: TestPlans,
    path: routes.PLANS,
    title: "Test plans",
    name: "Tests plans",
    permissions: [1, 2, 3],
    iconClass: "icon-folder",
    display: true
  },
  {
    component: Tests,
    path: routes.TESTS,
    title: "Tests",
    name: "Tests",
    permissions: [1, 2, 3],
    iconClass: "icon-file",
    display: true
  },
  {
    component: Settings,
    path: routes.PLAN_SETTINGS,
    title: "Plan Settings page",
    permissions: [1, 2, 3]
  },
  {
    component: Plan,
    path: routes.TEST_PLAN,
    title: "Plan page",
    permissions: [1, 2, 3]
  },
  {
    component: Settings,
    title: "Global Settings",
    path: routes.SETTINGS,
    name: "Global Settings",
    permissions: [1, 2, 3],
    iconClass: "icon-gears",
    display: true
  },
  {
    component: Node,
    title: "Node page",
    path: routes.NODE,
    permissions: [1, 2, 3]
  },
  {
    component: Node,
    title: "Adding Node",
    path: routes.CREATE_NODE,
    permissions: [1, 2, 3]
  },
  {
    component: Nodes,
    title: "Sift Nodes",
    name: "Sift Nodes",
    path: routes.NODES,
    permissions: [1, 2, 3],
    iconClass: "icon-tree",
    display: true
  },
  {
    component: Users,
    path: routes.USERS,
    title: "Added Users",
    name: "Users",
    permissions: [1, 2],
    iconClass: "icon-organization",
    display: true
  },
  {
    component: Invites,
    path: routes.INVITES,
    title: "Invite list",
    permissions: [0, 1]
  }
];
