export const convertDateTime = (inputDate: string): string => {
  const activity = new Date(inputDate);
  return `${activity.toLocaleDateString()} ${activity.toLocaleTimeString()}`;
};

export const generateSearchString = (params: Array<string>) => {
  let string = "";

  if (params.length) {
    params.forEach((param: string) => {
      string += `&${param}`;
    });
  }

  return string;
};

export const splitLink = (link: string | null) => {
  const arr = link ? link.split("/") : null;

  return arr ? arr[arr.length - 1] : "";
};

export const getType = (status: number) => {
  if (status === 203) {
    return "info";
  } else if (status >= 200 && status < 300) {
    return "success";
  } else if (status >= 400 && status < 500) {
    return "warning";
  } else if (status >= 500 && status < 600) {
    return "error";
  }
};
